import { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import { PageLoader } from "./shared/ui/page-loader";
import { AppProvider, AuthProvider } from "./shared/lib";
import { initErrorTracker } from "./shared/error";

// import { Router } from "./Router";

const Router = lazy(() =>
	import("./Router").then((mod) => ({ default: mod.Router })),
);

initErrorTracker();

import "./index.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<AppProvider>
		<AuthProvider>
			<Router />
		</AuthProvider>
	</AppProvider>,
);
