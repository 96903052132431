import { APIError, NetworkError } from "@/shared/error";
import { logger } from "@/shared/logger";
import { getAPIEndpoint, ENV, JWT_ENABLED } from "@/shared/constants";
import { getAuthState } from "@/shared/lib/auth";

import { getApiKey, setToken } from "./auth";

type APIResponseSuccess<TDecode> = {
	type: "success";
	decode: TDecode;
	error: false;
	message: false;
	advice: false;
	system: false;
	api_key: string;
	mode: number;
};

type APIResponseError = {
	type: "warning" | "message";
	decode: false;
	error: number;
	message: string;
	advice: string;
	system: string;
	api_key: false;
	mode: number;
};

export type APIResponse<TDecode> =
	| APIResponseSuccess<TDecode>
	| APIResponseError;

function patchFetch() {
	const { fetch: origFetch, Request } = globalThis;

	globalThis.fetch = async function (
		input: RequestInfo | URL,
		init?: RequestInit,
	): Promise<Response> {
		const request = new Request(input, init);
		const stack = new Error().stack;

		try {
			return await origFetch.call(this, request);
		} catch (err) {
			if (err instanceof Error) {
				err.stack = stack;

				if (err.name === "AbortError") {
					throw err;
				}

				throw new NetworkError(err.message);
			}

			throw err;
		}
	};
}

patchFetch();

type FetchOptions = {
	headers?: Record<string, string>;
};

// Универсальная функция для работы с fetch
async function fetchAPI<T>(path: string, init?: RequestInit): Promise<T> {
	const url = `${getAPIEndpoint()}/json${path}`;
	const authState = getAuthState();

	logger.info(`[${init?.method || "GET"}] ${url}`, init?.body);

	const headers = init ? new Headers(init.headers) : new Headers();

	headers.set("Content-Type", "application/json");
	headers.set("X-Referer", location.href);

	if (JWT_ENABLED) {
		if (authState?.accessToken) {
			headers.set("Authorization", `Bearer ${authState.accessToken}`);
		} else {
			headers.delete("Authorization");
		}
	}

	const res = await fetch(url, {
		...init,
		headers,
	});

	const data = (await res.json()) as APIResponse<T>;

	// Update API key
	if (data.api_key) {
		setToken(data.api_key);
	}

	if (data.type !== "success") {
		throw new APIError({
			type: data.type,
			code: data.error ? String(data.error) : "unknown_error",
			message: data.message || "Произошла ошибка",
			advice: data.advice,
			system: data.system,
			error: data.error,
		});
	}

	if (res.status !== 200) {
		throw new Error("API error");
	}

	return data.decode;
}

// Универсальная функция GET-запроса
export async function get<T>(path: string, options?: FetchOptions): Promise<T> {
	return fetchAPI<T>(path, {
		method: "GET",
		headers: options?.headers,
	});
}

// Универсальная функция POST-запроса
export async function post<T>(
	path: string,
	body: object,
	options?: FetchOptions,
): Promise<T> {
	const api_key = getApiKey();

	return fetchAPI<T>(path, {
		method: "POST",
		body: JSON.stringify({
			api_key,
			...body,
		}),
		headers: options?.headers,
	});
}

// Универсальная функция PUT-запроса
export async function put<T>(
	path: string,
	body: object,
	options?: FetchOptions,
): Promise<T> {
	const api_key = getApiKey();

	return fetchAPI<T>(path, {
		method: "PUT",
		body: JSON.stringify({
			api_key,
			...body,
		}),
		headers: options?.headers,
	});
}
