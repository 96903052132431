import { createContext, useContext, useEffect, useRef, useState } from "react";

import { apiService } from "@/shared/api";
import { Hour, Minute } from "@/shared/utils";
import { getAuthState } from "@/shared/lib";
import { JWT_ENABLED } from "@/shared/constants";

type AuthContextValue = {
	auth: (args: { guid: string }) => Promise<apiService.AlfaAuthResult>;
};

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

type AuthProvider = {
	children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProvider> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(JWT_ENABLED);
	const refreshTimeoutRef = useRef<Timer | null>(null);

	const refresh = () => {
		const auth = getAuthState();

		const needRefresh = !(
			auth.tokenExpiredAt &&
			new Date(auth.tokenExpiredAt).getTime() > Date.now()
		);

		if (auth.refreshToken && needRefresh) {
			return apiService
				.alfaAuthRefresh({
					refreshToken: auth.refreshToken,
				})
				.catch((err) => {
					if (refreshTimeoutRef.current) {
						clearTimeout(refreshTimeoutRef.current);
					}

					throw err;
				})
				.then((data) => {
					const nextRefreshTime =
						new Date(data.tokenExpiredAt).getTime() - (Date.now() - Minute);

					refreshTimeoutRef.current = setTimeout(refresh, nextRefreshTime);
				})
				.finally(() => setIsLoading(false));
		}
	};

	const auth = async (args: { guid: string }) => {
		return apiService
			.alfaAuth({ guid: args.guid })
			.catch((err) => {
				if (refreshTimeoutRef.current) {
					clearTimeout(refreshTimeoutRef.current);
				}

				throw err;
			})
			.then((data) => {
				const nextRefreshTime =
					new Date(data.auth.tokenExpiredAt).getTime() - (Date.now() - Minute);

				if (JWT_ENABLED) {
					refreshTimeoutRef.current = setTimeout(refresh, nextRefreshTime);
				}

				return data;
			});
	};

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			refresh();
		} else {
			if (refreshTimeoutRef.current) {
				clearTimeout(refreshTimeoutRef.current);
			}
		}
	};

	useEffect(() => {
		if (JWT_ENABLED) {
			document.addEventListener("visibilitychange", handleVisibilityChange);

			return () => {
				document.removeEventListener(
					"visibilitychange",
					handleVisibilityChange,
				);
			};
		}
	}, []);

	useEffect(() => {
		if (JWT_ENABLED) {
			const url = new URL(location.href);
			const { pathname } = url;

			if (!(pathname === "/" || pathname.startsWith("/alfa-auth"))) {
				refresh();
			} else {
				setIsLoading(false);
			}

			return () => {
				if (refreshTimeoutRef.current) {
					clearTimeout(refreshTimeoutRef.current);
				}
			};
		}
	}, []);

	if (isLoading) {
		return null;
	}

	return (
		<AuthContext.Provider value={{ auth }}>{children}</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
