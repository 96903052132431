import { post } from "../fetch";

export type VenueExt = {
	id: string;
	name: string;
	address: string;
	geoLat: string;
	geoLon: string;
	description?: string;
	workingHours?: string;
	phone?: string;
	facilities?: Array<{
		id: string;
		name: string;
	}>;
	halls?: Array<{
		id: string;
		name: string;
		capacity: number;
		features?: string[];
	}>;
	images?: string[];
};

export type GetVenuesResult = {
	venues: VenueExt[];
};

const mockVenues: VenueExt[] = [
	{
		id: "1",
		name: "Кинотеатр Москва",
		address: "ул. Охотный ряд, 2",
		geoLat: "55.757539",
		geoLon: "37.615580",
		description: "Современный кинотеатр в центре города с IMAX залом",
		workingHours: "10:00-23:00",
		phone: "+7 (495) 123-45-67",
		facilities: [
			{ id: "1", name: "IMAX" },
			{ id: "2", name: "Кафе" },
			{ id: "3", name: "Парковка" },
		],
		halls: [
			{ id: "1", name: "IMAX", capacity: 300, features: ["Dolby Atmos", "4K"] },
			{ id: "2", name: "Зал 2", capacity: 150, features: ["3D"] },
			{
				id: "3",
				name: "VIP",
				capacity: 50,
				features: ["Кожаные кресла", "Обслуживание в зале"],
			},
		],
		images: [
			"https://example.com/moscow-cinema1.jpg",
			"https://example.com/moscow-cinema2.jpg",
		],
	},
	{
		id: "2",
		name: "Киноцентр Премьер",
		address: "Ленинский проспект, 15",
		geoLat: "55.706989",
		geoLon: "37.578438",
		description: "Уютный кинотеатр с отличным звуком и комфортными креслами",
		workingHours: "11:00-00:00",
		phone: "+7 (495) 234-56-78",
		facilities: [
			{ id: "4", name: "Dolby Atmos" },
			{ id: "5", name: "Попкорн-бар" },
		],
		halls: [
			{
				id: "4",
				name: "Большой зал",
				capacity: 200,
				features: ["Dolby Atmos"],
			},
			{ id: "5", name: "Малый зал", capacity: 100 },
		],
		images: ["https://example.com/premier-cinema1.jpg"],
	},
	{
		id: "3",
		name: "Синема Парк",
		address: "пр. Мира, 211",
		geoLat: "55.835211",
		geoLon: "37.619295",
		description: "Мультиплекс с 8 залами и новейшим оборудованием",
		workingHours: "10:00-02:00",
		phone: "+7 (495) 345-67-89",
		facilities: [
			{ id: "6", name: "4DX" },
			{ id: "7", name: "ScreenX" },
			{ id: "8", name: "Игровая зона" },
		],
		halls: [
			{ id: "6", name: "4DX", capacity: 120, features: ["4D эффекты"] },
			{ id: "7", name: "ScreenX", capacity: 180, features: ["270° экран"] },
			{ id: "8", name: "Стандарт", capacity: 140 },
		],
		images: [
			"https://example.com/cinema-park1.jpg",
			"https://example.com/cinema-park2.jpg",
			"https://example.com/cinema-park3.jpg",
		],
	},
	{
		id: "4",
		name: "Арт-хаус",
		address: "ул. Пушкина, 23",
		geoLat: "55.762115",
		geoLon: "37.608593",
		description: "Кинотеатр авторского кино с уникальной атмосферой",
		workingHours: "13:00-23:00",
		phone: "+7 (495) 456-78-90",
		facilities: [
			{ id: "9", name: "Арт-кафе" },
			{ id: "10", name: "Книжный магазин" },
		],
		halls: [
			{ id: "9", name: "Зал 1", capacity: 80, features: ["Винтажные кресла"] },
			{ id: "10", name: "Камерный зал", capacity: 40, features: ["Диваны"] },
		],
		images: [
			"https://example.com/arthouse1.jpg",
			"https://example.com/arthouse2.jpg",
		],
	},
];

export function getVenues(args: { cityId: string }): Promise<GetVenuesResult> {
	// Return mock data instead of making API call
	return Promise.resolve({ venues: mockVenues });
	// return get<GetVenuesResult>(`/api/v1/cities/${args.cityId}/cinema/venues`);
}
