import { createContext, useContext, useRef } from "react";
import { createStore, useStore } from "zustand";
import { startOfDay } from "date-fns";

type Filters = {
	query: string;
	date: Date;
	format: string | null;
};

type FiltersState = Filters & {
	setQuery: (query: Filters["query"]) => void;
	setFormat: (genresIds: Filters["format"]) => void;
	setDate: (date: Filters["date"]) => void;
	resetFilters: (filters?: Filters) => void;
};

const initialFiltersState: Filters = {
	query: "",
	date: new Date(),
	format: null,
};

const createCinemaFiltersStore = (initProps?: Partial<Filters>) => {
	return createStore<FiltersState>()((set) => ({
		...initialFiltersState,
		...initProps,
		setQuery: (query) => set({ query }),
		setFormat: (format) => set({ format }),
		setDate: (date) => set({ date }),
		resetFilters: (filters) => set(filters ?? initialFiltersState),
	}));
};

type CinemaFiltersStore = ReturnType<typeof createCinemaFiltersStore>;

export const CinemaFiltersContext = createContext<CinemaFiltersStore | null>(
	null,
);

export function useCinemaFiltersContext<T>(
	selector: (state: FiltersState) => T,
): T {
	const store = useContext(CinemaFiltersContext);
	if (!store)
		throw new Error("Missing CinemaFiltersContext.Provider in the tree");

	return useStore(store, selector);
}

type CinemaFiltersProviderProps = {
	initialState?: Partial<Filters>;
	children: React.ReactNode;
};

export const CinemaFiltersProvider = ({
	initialState,
	children,
}: CinemaFiltersProviderProps) => {
	const storeRef = useRef<CinemaFiltersStore | null>(null);
	if (!storeRef.current) {
		storeRef.current = createCinemaFiltersStore({ ...initialState });
	}

	return (
		<CinemaFiltersContext.Provider value={storeRef.current}>
			{children}
		</CinemaFiltersContext.Provider>
	);
};
