type AuthState = {
	accessToken: string;
	tokenExpiredAt: string;
	refreshTokenExpiredAt: string;
} | null;

let state: AuthState = null;

export function getAuthState() {
	const refreshToken = localStorage.getItem("refreshToken");

	return {
		...state,
		refreshToken,
	};
}

export function setAuthState(args: AuthState) {
	state = args;
}
