export const MAX_CITIES_HISTORY_ITEMS = 5;
export const MAX_PLACES_HISTORY_ITEMS = 5;
export const MAX_SEARCH_RESULTS = 50;
export const MAX_POPULAR_PLACES = 15;

export const ENV =
	typeof process !== "undefined"
		? (process.env.PUBLIC_DEPLOY_ENV as "devstg" | "prod")
		: "prod";

export const API_ENDPOINT = {
	devstg: "https://api-alfa-test.kassir.ru",
	prod: "https://api-alfa.kassir.ru",
}[ENV];

export function getRuntimeEnv() {
	if (location.host === "www.alfa-dev1.kassirdev.ru") {
		return "dev1";
	}

	if (location.host === "www.alfa-dev2.kassirdev.ru") {
		return "dev2";
	}

	if (location.host === "alfa-test.kassir.ru") {
		return "stg";
	}

	if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
		return "dev";
	}

	return "prod";
}

export function getAPIEndpoint() {
	const runtimeEnv = getRuntimeEnv();

	if (ENV === "prod") {
		return "https://api-alfa.kassir.ru";
	}

	if (runtimeEnv === "dev1") {
		return "https://api.alfa-dev1.kassirdev.ru";
	}

	if (runtimeEnv === "dev2") {
		return "https://api.alfa-dev2.kassirdev.ru";
	}

	if (runtimeEnv === "stg") {
		return "https://api-alfa-test.kassir.ru";
	}

	if (runtimeEnv === "dev") {
		return "https://api-alfa-test.kassir.ru";
	}

	return "https://api-alfa.kassir.ru";
}

// export const SENTRY_DSN =
// 	"https://9e6bd9aeba845ea63e5dc1881ec15f58@o249182.ingest.us.sentry.io/4507864255954944";

export const SENTRY_DSN =
	"https://10e6640680d24cfb819a183661fb4065@o4508734483529728.ingest.de.sentry.io/4508736645431376";

export const JWT_ENABLED = true;

export const YANDEX_MAPS_API_KEY = "0af7f901ca5f2e4072221cac87b66d9bb67c86c0";
