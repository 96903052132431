import { post } from "../fetch";

export type Kind = {
	kindId: number;
	kindName: string;
};

export type ActionEvent = {
	actionEventAlterText: string;
	categories: boolean;
	eventId: number;
	placements: boolean;
	sellEndTime: string;
	eventTime: string;
	fanIdRequired: boolean;
	cashback: number;
};

export type ActionExt = {
	actionId: number;
	actionName: string;
	address: string;
	age: string;
	description?: string;
	from: string;
	to: string;
	fullActionName: string;
	geoLat: string;
	geoLon: string;
	kdp: boolean;
	poster: string;
	venueId: number;
	venueName: string;
	events: Array<ActionEvent>;
	venues: Array<{
		venueId: number;
		venueName: string;
	}>;
	minPrice?: number;
	maxPrice?: number;
	cashback: number;
	minCashback: number;
	maxCashback: number;
	when?: string;
	posterFormat: "default" | "1280x392" | "1242x800";
	soldout_events: [];
	new_orders: {
		orders: number;
		time: number;
		reservationExpiredAt: string | null;
	};
	type: "cinema" | "common";
};

export type GetActionResult = ActionExt;

export function getAction(args: {
	actionId: string;
	venueId: string;
	cityId: string;
}) {
	return post<GetActionResult>("/get_action_ext", {
		aid: args.actionId,
		vid: args.venueId,
		cid: args.cityId,
	});
}

type GetCinemaActionResult = {
	title: string;
	originalTitle: string;
	poster: string;
	description: string;
	year: number;
	directors: Array<{
		id: string;
		title: string;
	}>;
	actors: Array<{
		id: string;
		title: string;
	}>;
	screenshots: string[];
	countries: Array<{
		id: string;
		title: string;
	}>;
	duration: number | null;
	genres: Array<{
		id: string;
		title: string;
	}>;
	trailer: string | null;
	trailerPreview: string | null;
	cashback: number;
};

export function getCinemaAction(args: {
	cityId: string;
	actionId: string;
}) {
	return post<GetCinemaActionResult>("/cinema/action", {
		cid: args.cityId,
		aid: args.actionId,
	});
}

export type CinemaActionFormat = {
	id: number;
	title: string;
};

export type CinemaActionVenue = {
	id: string;
	name: string;
	address: string;
	cityId: string;
	events: Array<{
		id: string;
		date: string;
		minPrice: number | null;
		formats: Array<CinemaActionFormat>;
	}>;
};

export type GetCinemaActionEventsResult = {
	venues: Array<CinemaActionVenue>;
	formats: Array<CinemaActionFormat>;
};

export function getCinemaActionEvents(args: {
	cityId: string;
	actionId: string;
}) {
	return post<GetCinemaActionEventsResult>("/cinema/events", {
		cid: args.cityId,
		aid: args.actionId,
	});
}
